<template>
  <div class="Member bg-white">
    <Row class="px-1">
      <i-col span="24" class="align-justify">
        <Button type="default" icon="md-refresh" size="large" class="mr-1"></Button>
        <Button type="default" icon="md-add" size="large" class="mr-1" @click="addActiivity"></Button>
        <Select
          class="mr-1"
          size="large"
          style="width: 7rem"
          v-model="activitystate"
          placeholder="活动状态"
          @on-select="selectActivityState"
        >
          <Option :value="-1" selected>全部</Option>
          <Option :value="1">正在进行</Option>
          <Option :value="0">已结束</Option>
        </Select>
        <i-input
          v-model="keyword"
          size="large"
          style="width: 15rem"
          class="el-inline mr-1"
          placeholder="输入关键字进行查询"
        ></i-input>
        <Button
          type="primary"
          icon="md-search"
          size="large"
          class="mr-1"
          @click="getActivityList"
        >查询</Button>
      </i-col>
      <i-col span="24">
        <Table
          border
          stripe
          ref="usersTable"
          :loading="searching"
          :columns="tColumns"
          :data="tData"
          :height="theight"
          class="mt-1"
        >
          <div slot-scope="{ row }" slot="action">
            <Button type="primary" size="small" style="margin-right: 0.25rem" @click="show(row)">详情</Button>
            <Button
              type="success"
              size="small"
              style="margin-right: 0.25rem"
              @click="allStart(row)"
            >全部激活</Button>
            <Button type="warning" size="small" @click="allStop(row)">全部停止</Button>
          </div>
        </Table>
        <Page
          :total="recordTotal"
          :page-size="10"
          show-total
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
    </Row>
    <Drawer title="添加子活动" v-model="draw" width="720" :mask-closable="false">
      <Form></Form>
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="draw = false">取消</Button>
        <Button type="primary" @click="addchildrenActivity">确定</Button>
      </div>
    </Drawer>
    <Modal v-model="showState1" title="更改活动基本信息" ok-text="修改" @on-ok="updateActivity">
      <Form :label-width="70" v-model="checkActivityInfo">
        <Row class="px-1">
          <i-col span="24">
            <FormItem label="活动名称" label-position="top">
              <Input v-model="checkActivityInfo.activityName" size="large" placeholder="活动名称" />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="活动介绍" label-position="top">
              <quill-editor
                ref="activityContent"
                :options="editorOption"
                v-model="checkActivityInfo.activityContent"
              ></quill-editor>
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="活动图片" label-position="top">
              <div class="demo-upload-list">
                <img
                  style="width:50%"
                  :src="'https://fzapi.hxypsy.cn'+checkActivityInfo.activityPhotos"
                />
              </div>
              <Upload
                type="drag"
                :format="['jpg','jpeg','png']"
                :on-success="handleSuccess"
                :max-size="2048"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                action="https://fzapi.hxypsy.cn/api/Activity/UploadImage"
                style="display: inline-block;width:58px;"
              >
                <div style="width: 58px;height:58px;line-height: 58px;">
                  <Icon type="ios-camera" size="20"></Icon>
                </div>
              </Upload>
            </FormItem>
          </i-col>
        </Row>
      </Form>
    </Modal>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import expandRow from "../components/table-expand";
export default {
  name: "Activity",
  components: { expandRow, quillEditor },
  data() {
    return {
      editorOption: {
        placeholder: "",
        theme: "bubble",
      },
      showState1: false,
      showState2: false,
      draw: false,
      searching: true,
      activityInfo: {},
      keyword: "",
      activitystate: -1,
      tData: [],
      tColumns: [
        {
          type: "expand",
          width: 45,
          render: (h, params) => {
            return h(expandRow, {
              props: {
                row: params.row,
              },
            });
          },
        },
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "活动名称",
          key: "activityName",
          minWidth: 100,
          ellipsis: true,
        },
        {
          title: "活动描述",
          key: "activityContent",
          minWidth: 300,
          ellipsis: true,
          render: (h, params) => {
            return h("div", {
              domProps: {
                innerHTML: `${params.row.activityContent}`,
              },
            });
          },
        },
        {
          title: "活动图片",
          minWidth: 80,
          key: "activityPhotos",
          align: "center",
          render: (h, params) => {
            let devicesArr = [];
            let photo = [];
            photo.push("https://fzapi.hxypsy.cn" + params.row.activityPhotos);
            devicesArr.push(
              h(
                "viewer",
                {
                  props: {
                    images: photo,
                  },
                },
                [
                  h("img", {
                    attrs: {
                      src: photo[0],
                      style: "width: 60px;height:60px;margin-right:5px",
                    },
                  }),
                ]
              )
            );
            return h("div", devicesArr);
          },
        },
        {
          title: "设置时间",
          key: "setTime",
          width: 180,
          render: (h, params) => {
            let dt = this.myjs.subDate(params.row.setTime);
            return h("span", dt);
          },
          sortable: true,
          sortType: "desc",
        },
        {
          title: "操作",
          slot: "action",
          width: 220,
        },
      ],
      pageSize: 10,
      recordTotal: 0,
      pageNum: 1,
      theight: window.innerHeight - 175,
      checkActivityInfo: {},
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    this.getActivityList();
  },
  methods: {
    handleSuccess(res, file) {
      this.activityInfo.photos = res;
    },
    handleFormatError(file) {
      this.$Notice.error({
        title: "错误",
        desc: "文件 " + file.name + " 格式错误, 请选择 jpg 或者 png.",
      });
    },
    handleMaxSize(file) {
      this.$Notice.error({
        title: "错误",
        desc: "文件 " + file.name + " 过大, 请选择不超过2M的文件.",
      });
    },

    updateActivity() {},
    allStart(row) {
      this.setAllActivityState(row.activityId, 1);
    },
    allStop(row) {
      this.setAllActivityState(row.activityId, 0);
    },
    setAllActivityState(id, value) {
      this.$post("Activity/ChangeAllActivityState", {
        ActivityId: id,
        RunState: value,
      }).then((res) => {
        if (res.data.code) {
          this.$Message.success({
            content: "更改成功",
            duration: 3,
          });
          this.getActivityList();
        } else {
          this.$Message.error({
            content: "更改失败，请稍后重试",
            duration: 3,
          });
        }
      });
    },
    addchildrenActivity() {},
    changeSize(e) {
      this.pageSize = e;
      this.getActivityList();
    },
    changePage(e) {
      this.pageNum = e;
      this.getActivityList();
    },
    addchildren(row) {
      this.draw = true;
    },
    remove(row) {},
    show(row) {
      this.checkActivityInfo = row;
      this.showState1 = true;
    },
    addActiivity() {
      this.$router.push({ name: "ActivityDesign" });
    },
    selectActivityState(e) {
      console.log(e);
      this.activitystate = e.value;
      this.pageNum = 1;
      this.getActivityList();
    },
    getActivityList() {
      this.searching = true;
      this.$get("Activity/GetActivityList", {
        state: this.activitystate,
        keyword: this.keyword,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }).then((res) => {
        this.tData = res.data.responseList;
        this.recordTotal = res.data.total;
        this.searching = false;
        this.$Message.success({
          content: "加载成功",
          duration: 3,
        });
      });
    },
  },
};
</script>
<style>
@import "../assets/quill.css";
.quill-editor {
  height: 8rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #ccc;
}
</style>