<template>
  <div>
    <Table :columns="columnsdata" :data="tabledata">
      <div slot-scope="{ row }" slot="action">
        <Button
          type="primary"
          size="small"
          style="margin-right: 0.25rem"
          @click="showApplyInfo(row)"
        >详情</Button>
        <Button size="small" @click="showApplyInfo(row)">查看报名数据</Button>
      </div>
    </Table>
    <Modal v-model="showState2" title="更改阶段活动信息" ok-text="修改" @on-ok="updateChildActivity">
      <Form :label-width="70" v-model="checkChildActivityInfo">
        <Row class="px-1">
          <i-col span="24">
            <FormItem label="活动名称" label-position="top">
              <Input
                v-model="checkChildActivityInfo.childActivityName"
                size="large"
                placeholder="活动名称"
              />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="活动地址" label-position="top">
              <Input
                type="textarea"
                :rows="2"
                v-model="checkChildActivityInfo.childActivityAddress"
                size="large"
                placeholder="活动地址"
              />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="限制人数" label-position="top">
              <Input v-model="checkChildActivityInfo.limitPeople" size="large" placeholder="限制人数" />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="已有报名" label-position="top">
              <Input v-model="checkChildActivityInfo.applied" size="large" readonly />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="报名费用" label-position="top">
              <Input v-model="checkChildActivityInfo.applyPrice" size="large" placeholder="报名费用" />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="阶段说明" label-position="top">
              <Input
                type="textarea"
                :rows="3"
                maxlength="150"
                show-word-limit
                v-model="checkChildActivityInfo.childActivityDesc"
                size="large"
                placeholder="活动地址"
              />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="活动时间" label-position="top">
              <DatePicker
                v-model="checkChildActivityInfo.activityTime"
                type="datetimerange"
                placement="bottom-start"
                size="large"
                style="width:100%;"
                placeholder="请选择阶段活动时间"
                :on-ok="checkTime_(checkChildActivityInfo)"
              ></DatePicker>
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="截止时间" label-position="top">
              <DatePicker
                v-model="checkChildActivityInfo.deadline"
                type="datetime"
                placement="bottom-start"
                size="large"
                style="width:100%"
                placeholder="请选择阶段活动截止时间"
              ></DatePicker>
            </FormItem>
          </i-col>
        </Row>
      </Form>
    </Modal>
  </div>
</template>
<script>
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      checkChildActivityInfo: {},
      showState2: false,
      tabledata: [],
      columnsdata: [
        {
          type: "index",
          title: "序号",
          align: "center",
          width: 50,
        },
        {
          title: "阶段名称",
          key: "childActivityName",
          width: 120,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "阶段地址",
          key: "childActivityAddress",
          width: 120,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "限制人数",
          key: "limitPeople",
          width: 80,
        },
        {
          title: "已报名",
          key: "applied",
          width: 80,
        },
        {
          title: "报名费用",
          key: "applyPrice",
          width: 80,
        },
        {
          title: "活动开始时间",
          key: "startTime",
          render: (h, params) => {
            let dt =
              this.myjs.dateFormat(params.row.startTime) +
              " " +
              this.myjs.timeFormat(params.row.startTime);
            return h("span", dt);
          },
        },
        {
          title: "活动结束时间",
          key: "endTime",
          render: (h, params) => {
            let dt =
              this.myjs.dateFormat(params.row.endTime) +
              " " +
              this.myjs.timeFormat(params.row.endTime);
            return h("span", dt);
          },
        },
        {
          title: "报名截止时间",
          key: "deadline",
          render: (h, params) => {
            let dt =
              this.myjs.dateFormat(params.row.deadline) +
              " " +
              this.myjs.timeFormat(params.row.deadline);
            return h("span", dt);
          },
        },
        {
          title: "说明",
          key: "childActivityDesc",
          tooltip: true,
          ellipsis: true,
        },
        {
          title: "状态",
          key: "runState",
          align: "center",
          width: 80,
          render: (h, params) => {
            return h("i-switch", {
              props: {
                size: "large",
                value: params.row.runState === 1,
                "true-color": "#5cadff",
              },
              scopedSlots: {
                open: () => h("span", "开启"),
                close: () => h("span", "关闭"),
              },
              on: {
                "on-change": (value) => {
                  this.changeUseState(params.row, value);
                },
              },
            });
          },
        },
        {
          title: "操作",
          align: "center",
          slot: "action",
          width: 202,
        },
      ],
    };
  },
  created() {
    this.tabledata = this.row.childrenActivity;
  },
  methods: {
    checkTime_(item) {},
    updateChildActivity() {},
    showApplyInfo(row) {
      this.checkChildActivityInfo = row;
      this.checkChildActivityInfo.activityTime = [];
      this.checkChildActivityInfo.activityTime.push(row.startTime);
      this.checkChildActivityInfo.activityTime.push(row.endTime);
      this.showState2 = true;
    },
    changeUseState(row, value) {
      if (value) {
        value = 1;
      } else {
        value = 0;
      }
      this.$post("Activity/ChangeActivityState", {
        ChildActivityId: row.childActivityId,
        RunState: value,
      }).then((res) => {
        if (res.data.code) {
          this.$Message.success({
            content: "更改成功",
            duration: 3,
          });
        } else {
          this.$Message.error({
            content: "更改失败，请稍后重试",
            duration: 3,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
</style>